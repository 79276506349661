import * as React from "react";
import Helmet from "react-helmet";
import Header from "../Common/Header/Header";
import Footer from "../Common/Footer/Footer";
import "../Styles/Custom.css";
import "react-multi-carousel/lib/styles.css";
import "react-image-gallery/styles/css/image-gallery.css";
import ErrorBoundaryContainer from "../Common/ErrorBoundryContainer";

interface props {
  lang?: string
  title?: string
  description?: string
  readonly children: React.ReactNode
}

const Layout = ({ lang, title, description, children }: props) => {
  return (
    <>
      <ErrorBoundaryContainer>
        <Helmet>
          <html lang={lang ? lang : `en`} />
          <title>{title ? title : "Capitol Enquiry – Founded in 1973"}</title>
          <meta name="description" content={description ? description : ``} />
          {/* Chrome, Firefox OS and Opera */}
          <meta name="theme-color" content="#ff173d" />
          {/* Windows Phone */}
          <meta
            name="msapplication-navbutton-color"
            content="#ff173d"
          />
          {/* iOS Safari */}
          <meta
            name="apple-mobile-web-app-status-bar-style"
            content="#ff173d"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;600;800&family=Poppins:ital,wght@0,200;0,400;0,500;0,700;1,600&display=swap"
            rel="stylesheet"
          />
          <link
            rel="stylesheet"
            href="../../src/Assets/fontAwesome/css/min.css"
          />
        </Helmet>
        <Header />
        <div>
          <main>{children}</main>
        </div>
        <Footer />
      </ErrorBoundaryContainer>
    </>
  );
};

export default Layout;
