import * as React from "react"
import { Link } from "gatsby"
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap"
import MainLogo from "../../Assets/logo.svg"
import CartIcon from "../../Assets/Svg-Icons/cart.svg"
import Search from "../../Assets/Svg-Icons/search-16.svg"

const ProductsList = [
  {
    id: "1",
    title: "Govbuddy",
    link: "/govbuddy",
  },
  {
    id: "2",
    title: "Printed Directory",
    link: "/printed-directory",
  },
  {
    id: "3",
    title: "Maps",
    link: "/maps",
  },
  {
    id: "4",
    title: "Digital Editions",
    link: "/digital-editions",
  },
  {
    id: "5",
    title: "Policy Guru",
    link: "/policy-guru",
  },
  {
    id: "6",
    title: "Pritchard U",
    link: "/pritchardu",
  },
]

const Solutions = [
  { title: "Government", link: "/sol-government" },
  { title: "Trade Assoications", link: "/sol-trade" },
  { title: " Non Profits", link: "/sol-nonpro" },
  { title: "Lobbyist", link: "/sol-lobbyst" },
  { title: "Grassroots Efforts", link: "/sol-grassroots" },
  { title: "Developers", link: "/sol-developers" },
]

const MobileNav = () => {
  return (
    <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
      <Container fluid className="align-center text-center">
        <div className="col-12 d-flex align-items-center justify-content-between">
          <div className="col-6">
            <Navbar.Brand className="m-auto" style={{ paddingRight: "30px" }}>
              <Link to="/" className="d-flex align-items-center">
                <img
                  src={MainLogo}
                  style={{ marginTop: "20px" }}
                  alt="Company Logo"
                  width="100%"
                  height="100%"
                />
              </Link>
            </Navbar.Brand>
          </div>
          <div className="col-6 d-flex justify-content-end">
            <Nav.Item id="mobileHeader">
              <Nav.Item className="float-right" id="accounts">
                <Link to="/cart" className="btn cart-icon">
                  <span className="badge badge-warning" id="lblCartCountMobile">
                    19
                  </span>
                  <CartIcon />
                </Link>
              </Nav.Item>
            </Nav.Item>

            <Navbar.Toggle aria-controls="responsive-navbar-nav" id="navbar-toggler" />
          </div>
        </div>

        <div className="col-12">
          <form id="search" action="search" method="GET">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search Product Here..."
                aria-label="Search Product Here..."
                aria-describedby="button-addon2"
              />
              <button
                className="btn btn-primary btn-custom"
                type="submit"
                id="button-addon2"
              >
                <Search />
              </button>
            </div>
          </form>
        </div>

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link>
              <NavDropdown
                title="Products"
                className="text-start nav-link-mobile"
                id="collasible-nav-dropdown"
              >
                {ProductsList?.map(product => (
                  <NavDropdown.Item id="dropdown-menu" key={product.id}>
                    <Link className="dropdown-item" id="dropdown-item" to={product.link}>
                      {product.title}
                    </Link>
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
            </Nav.Link>

            <Nav.Link>
              <NavDropdown
                title="Solutions"
                className="text-start nav-link-mobile"
                id="collasible-nav-dropdown"
              >
                {Solutions?.map((solution, index) => (
                  <NavDropdown.Item id="dropdown-menu" key={index}>
                    <Link className="dropdown-item" id="dropdown-item" to={solution.link}>
                      {solution.title}
                    </Link>
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
            </Nav.Link>

            <Nav.Link className="text-start ">
              <Link
                className="nav-link nav-link-mobile"
                to="/about"
                role="button"
                aria-expanded="false"
              >
                About Us
              </Link>
            </Nav.Link>
            <Nav.Link className="text-start">
              <Link
                className="nav-link nav-link-mobile"
                to="/brands"
                role="button"
                aria-expanded="false"
              >
                Our Brands
              </Link>
            </Nav.Link>
            <Nav.Link className="text-start">
              <Link
                className="nav-link nav-link-mobile"
                to="/contact"
                role="button"
                aria-expanded="false"
              >
                Contact Us
              </Link>
            </Nav.Link>
            {/* <Nav.Link className="text-start">
              <Link
                className="nav-link nav-link-mobile"
                to="/register"
                role="button"
                aria-expanded="false"
              >
                Register
              </Link>
            </Nav.Link>
            <Nav.Link>
              <Link
                className="nav-link nav-link-mobile nav-link mobile-button"
                to="/login"
                role="button"
                aria-expanded="false"
              >
                Login
              </Link>
            </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default MobileNav
