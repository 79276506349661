import * as React from "react"
import DesktopNav from "./DesktopNav"
import MobileNav from "./MobileNav"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const Header = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return <>{isMobile ? <MobileNav /> : <DesktopNav />}</>
}
export default Header
